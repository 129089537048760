import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import api from "../Api";
import { GamerContext } from "../contexts/GamerContext"; // Import the GamerContext
import LocalApiLog from "./LocalApiLog";

const Auth = ({ tg_id, tg_username, onAuthComplete }) => {
  const [hasExecuted, setHasExecuted] = useState(false);
  const { setGamer } = useContext(GamerContext); // Use the setGamer function from the context

  useEffect(() => {
    const tgIdAsString = tg_id ? String(tg_id) : null;
    if (!tgIdAsString || !tg_username || hasExecuted) return

    const authenticateOrRegisterUser = async () => {
      LocalApiLog('tg_id inside authenticateOrRegisterUser function: ' + tgIdAsString);

      try {
        const response = await api.post("login", {
          tg_id: tgIdAsString,
          tg_username: tg_username,
        });

        console.log("Login successful:", response.data);

        // Extract the token and gamer data
        const { access_token, gamer } = response.data;
        LocalApiLog('gamer ' + gamer.id);
        // Store the access token in localStorage
        localStorage.setItem("access_token", access_token);

        // Set the Authorization header for subsequent requests
        api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

        // Store the gamer data in context
        setGamer(gamer);

        onAuthComplete("success");
      } catch (error) {
        LocalApiLog(error.response?.data || error.message);
        if (error.response && error.response.status === 401) {
          try {
            const registerResponse = await api.post("register", {
              tg_id: tg_id,
              tg_username: tg_username,
            });

            console.log("Registration successful:", registerResponse.data);

            // Extract the token and gamer data from registration response
            const { access_token, gamer } = registerResponse.data;

            // Store the access token in localStorage
            localStorage.setItem("access_token", access_token);

            // Set the Authorization header for subsequent requests
            api.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${access_token}`;

            // Store the gamer data in context
            setGamer(gamer);

            onAuthComplete("success");
            toast.success("Registration successful!");
          } catch (registerError) {
            console.error(
                "Registration failed:",
                registerError.response?.data || registerError.message
            );
            onAuthComplete("failed");
            toast.error("Registration failed. Please try again.");
          }
        } else {
          console.error(
              "An error occurred during authentication:",
              error.response?.data || error.message
          );
          onAuthComplete("failed");
          toast.error("Authentication failed. Please try again.");
        }
      }
    };

    authenticateOrRegisterUser();
    setHasExecuted(true);
  }, [hasExecuted, tg_id, tg_username, onAuthComplete, setGamer]);

  return null;
};

export default Auth;
