import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Function to dynamically load the telegram-web-app.js script
const loadTelegramScript = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = true;
        script.onload = () => resolve(true);  // وقتی اسکریپت بارگذاری شد
        script.onerror = () => reject(new Error("Failed to load Telegram Web App script"));
        document.head.appendChild(script);
    });
};

const root = ReactDOM.createRoot(document.getElementById("root"));
// ابتدا اسکریپت Telegram Web App را بارگذاری کنید
loadTelegramScript()
    .then(() => {
        // بعد از اینکه اسکریپت بارگذاری شد، اپلیکیشن را رندر کنید
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    })
    .catch((error) => {
        console.error(error);
    });

reportWebVitals();
