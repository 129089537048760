import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Auth from "./Auth"; // Import the Auth component
import "../App.css";
const WelcomePage = ({ onProgressComplete }) => {
  const [progress, setProgress] = useState(0);
  const [authStatus, setAuthStatus] = useState(null); // Tracks the status of authentication or registration
  const [tgInfo, setTgInfo] = useState({ tg_id: null, tg_username: null }); // Store Telegram info

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;

      // Extract user information from the Telegram WebApp object
      const userId = tg.initDataUnsafe?.user?.id || null;
      const username = tg.initDataUnsafe?.user?.username || null;
      if (!userId || !username) {
        toast.error("Missing Telegram credentials. Please restart the process.");
      } else {
        setTgInfo({ tg_id: userId, tg_username: username });
      }
    } else {
      toast.error("Telegram WebApp not detected. Please run this inside Telegram.");
    }
  }, []);

  const handleAuthComplete = (status) => {
    setAuthStatus(status);
  };


  // const authenticateUser = async () => {
  //   logToLocalApi('Authenticator started');
  //   const apiToken = process.env.REACT_APP_API_TOKEN;
  //   const apiUrl = process.env.REACT_APP_USE_NGROK === "true"
  //       ? process.env.REACT_APP_NGROK_BASE_URL
  //       : process.env.REACT_APP_API_BASE_URL;
  //
  //   try {
  //     const response = await axios.get(`${apiUrl}`);
  //     logToLocalApi(response);
  //     // Assuming the response contains a success status
  //     if (response.data.success) {
  //       setAuthStatus("success");
  //     } else {
  //       setAuthStatus("failed");
  //     }
  //   } catch (error) {
  //     logToLocalApi(error);
  //     console.error("Authentication failed:", error);
  //     toast.error("Authentication failed. Please try again.");
  //     setAuthStatus("failed");
  //   }
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 2;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {

    if (progress === 100) {
      if (authStatus === "success") {
        onProgressComplete(); // Proceed to the next step if authentication/registration is successful
      } else if (authStatus === "failed") {
        // Stay on the welcome page and show an error
        toast.error("Please try again later.");
      }
    }
  }, [progress, authStatus, tgInfo, onProgressComplete]);

  // useEffect(() => {
  //   authenticateUser();
  //
  // }, [tgInfo]);

  return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center max-w-md w-full">
          <Auth
              tg_id={tgInfo.tg_id} // Pass dynamic tg_id
              tg_username={tgInfo.tg_username} // Pass dynamic tg_username
              onAuthComplete={handleAuthComplete}
          />
          <div className="flex flex-col h-full">
            <div className="text-start mx-4 md:mx-10 mb-5">
              <h1 className="text-3xl md:text-5xl font-bold mb-2 bg-gradient-to-r from-cyan-500 to-blue-500 inline-block text-transparent bg-clip-text">
                Griffin coin
              </h1>
              <div className="mt-2 md:mt-5">
                <p className="bg-gradient-to-r from-cyan-500 to-blue-500 inline-block text-transparent bg-clip-text text-xs md:text-[10px]">
                  First 3 level referral game will be launched{" "}
                  <span className="font-bold">on Ton</span>
                </p>
                <h3 className="bg-gradient-to-r from-cyan-500 to-blue-500 text-transparent bg-clip-text text-xs md:text-[14px]">
                  This is just a beginning
                </h3>
                <p className="text-xs md:text-sm">How does that sound?</p>
              </div>
            </div>
            <div className="bottom-0 w-full my-4">
              <div className="text-center">
                <div className="flex justify-center items-center space-x-4 mb-4 py-3 md:py-6 max-w-full md:max-w-4xl rounded-2xl mx-4 md:mx-8 overflow-hidden shadow-lg bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                  <img
                      className="inline-block w-16 md:w-20"
                      src={process.env.PUBLIC_URL + "/assets/img/ton-coin.webp"}
                      alt="griffin"
                  />
                  <img
                      className="inline-block w-16 md:w-20"
                      src={process.env.PUBLIC_URL + "/assets/img/griffin-coin.webp"}
                      alt="ton"
                  />
                </div>
                <div className="w-48 md:w-60 mx-auto">
                  <img
                      src={
                          process.env.PUBLIC_URL + "/assets/img/welcome-griffin.webp"
                      }
                      alt="App Icon"
                      className="mx-auto mb-0 w-full"
                  />
                </div>
                <div className="progress-bar-container mt-2 md:mt-4 w-full md:w-1/2 lg:w-1/3 mx-auto">
                  <div
                      className="progress-bar h-2 bg-gradient-to-r from-cyan-500 to-blue-500"
                      style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <p className="mt-3 mb-2 text-xs">Loading...</p>
                <div className="mt-4">
                  <span className="font-bold text-xs">Stay tuned</span>
                  <p className="font-bold text-xs">
                    More info in official channels
                  </p>
                  <div className="flex justify-center items-center space-x-2 md:space-x-4 mt-2">
                    <img
                        className="inline-block w-8"
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/img/socials/telegram-50.webp"
                        }
                        alt="telegram"
                    />
                    <img
                        className="inline-block w-8"
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/img/socials/youtube-50.webp"
                        }
                        alt="youtube"
                    />
                    <img
                        className="inline-block w-8"
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/img/socials/x-50.webp"
                        }
                        alt="twitterx"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default WelcomePage;
