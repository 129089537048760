import api from "../Api";

const LocalApiLog = (message, error = [], is_error_object = false) => {
    try {
       let data = error;
        if (is_error_object === true) {
            data = {
                message: error.message,
                response: error.response ? error.response.data : null,
                stack: error.stack,
            };
        }
        api.post("log", {
            message: message,
            data: data
        });
    } catch (logError) {
        console.error("Failed to log to server", logError);
    }
};
export default LocalApiLog;
