import React, {useState, useEffect, useCallback, useRef} from "react";
import {motion} from "framer-motion";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {CheckCircle} from "react-feather";
import Loading from "../components/Loading"; // Import your custom Loading component
import api from "../Api";
import LocalApiLog from "./LocalApiLog"; // Import the Axios instance
import Telegram from "./TelegramWebAppComponent";
const Tabs = () => {
    const [activeTab, setActiveTab] = useState("active");
    const [tasks, setTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Page state for pagination
    const [hasMore, setHasMore] = useState(true); // Track if there are more items to load
    const containerRef = useRef(null);
    const [taskStatuses, setTaskStatuses] = useState({});

    const loadTasks = useCallback(async (reset = false) => {
        if (loading || (!hasMore && !reset)) return;
        setLoading(true);
        let endpoint = "";
        if (activeTab === "active") {
            endpoint = "/tasks"; // Endpoint for active tasks
        } else if (activeTab === "completed") {
            endpoint = "/gamer-done-tasks"; // Endpoint for completed tasks
        }

        try {
            const response = await api.get(endpoint, {params: {page: reset ? 1 : page}});
            // Assuming the API returns data in response.data.data for paginated results
            const responseData = response.data.data || response.data; // Adjust based on actual API response
            const updatedTasks = responseData.map((task) => ({
                ...task,
                status:
                    task.status === 'done'
                        ? 'completed'
                        : task.status === 'pending'
                            ? 'pending'
                            : task.status === 'claim'
                                ? 'claim'
                                : 'active'
            }));

            if (reset) {
                if (activeTab === "active") {
                    setTasks(updatedTasks);
                } else {
                    setCompletedTasks(updatedTasks);
                }
                setPage(2);
            } else {
                if (activeTab === "active") {
                    setTasks((prevTasks) => [...prevTasks, ...updatedTasks]);
                } else {
                    setCompletedTasks((prevTasks) => [...prevTasks, ...updatedTasks]);
                }
                setPage((prevPage) => prevPage + 1);
            }

            const newTaskStatuses = updatedTasks.reduce((statuses, task) => {
                statuses[task.id] = task.status;
                return statuses;
            }, {});

            setTaskStatuses((prevStatuses) => ({
                ...prevStatuses,
                ...newTaskStatuses,
            }));

            // Update pagination status
            setHasMore(response.data.current_page < response.data.last_page);
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to load tasks. Please try again later.");
        } finally {
            setLoading(false);
        }
    }, [page, activeTab, hasMore, loading]);

    useEffect(() => {
        loadTasks(true);
    }, [activeTab]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                loadTasks();
            }
        }
    }, [hasMore, loading, loadTasks]);

    const handleTabClick = (tab) => {
        if (tab !== activeTab) {
            setActiveTab(tab);
        }
    };

    const getImageForPlatform = (platform) => {
        switch (platform.toLowerCase()) {
            case "youtube":
                return "./assets/img/socials/youtube-50.webp";
            case "telegram":
                return "./assets/img/socials/telegram-50.webp";
            case "xcom":
                return "./assets/img/socials/x-50.webp";
            default:
                return "./assets/img/socials/default-50.webp"; // Fallback image
        }
    };

    const getChatIdFromUrl = (url) => {
        const username = url.split("t.me/")[1]; // استخراج username از URL
        return username;
    };

    const checkTelegramMembership = async (username) => {
        const botToken = process.env.REACT_APP_API_TOKEN; // توکن بات تلگرام شما
        try {
            if (!window.Telegram || !window.Telegram.WebApp) {
                throw new Error("Telegram WebApp is not available.");
            }

            const userId = Number(window.Telegram.WebApp.initDataUnsafe?.user?.id);
            if (isNaN(userId)) {
                throw new Error("Invalid user ID");
            }

            const chatId = username.startsWith("@") ? username : `@${username}`;
            if (!userId) {
                throw new Error("User data is not available in Telegram WebApp.");
            }
LocalApiLog('data' , [userId , chatId , botToken])
            const response = await api.post(`https://api.telegram.org/bot${botToken}/getChatMember`, {
                    chat_id: chatId,
                    user_id: userId
                }
            );
            return response.data.result.status !== 'left'; // اگر کاربر عضو گروه باشد
        } catch (error) {
            LocalApiLog('checkTelegramMembership', error , true);
            throw new Error("Failed to check Telegram membership.");
        }
    };

    const handleStartTask = async (task) => {
        LocalApiLog(api.defaults.headers.common["Authorization"]);
        let taskId = task.id;
        let taskPlatform = task.platform;
        let taskUrl = task.url;
        setTaskStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: 'pending'
        }));
        let requestData = {}; // داده‌های درخواست خالی برای پلتفرم‌های غیر تلگرام

        // بررسی عضویت در گروه تلگرام فقط برای پلتفرم تلگرام
        if (taskPlatform === "Telegram" && taskUrl) {
            const username = getChatIdFromUrl(taskUrl);
            try {
                const isMember = await checkTelegramMembership(username);
                if (!isMember) {
                    toast.error("Please join the Telegram group to proceed.");
                    setTaskStatuses((prevStatuses) => ({
                        ...prevStatuses,
                        [taskId]: 'active'
                    }));
                    return;
                }
                requestData.task_is_done = true; // اگر عضو بود، مقدار task_is_done را true تنظیم می‌کند
            } catch (error) {
                toast.error("Failed to verify Telegram group membership.");
                LocalApiLog('error'  , error , true);
                setTaskStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [taskId]: 'active'
                }));
                return;
            }
        }

        // ارسال درخواست به endpoint با داده‌های مناسب برای پلتفرم تلگرام یا سایر پلتفرم‌ها
        api
            .post(`/tasker/${taskId}`, requestData)
            .then((response) => {
                if (response.status === 200) {
                    // تغییر وضعیت بر اساس وضعیت دریافتی از سرور
                    const newStatus = response.data.status === 'done'
                        ? 'completed'
                        : response.data.status === 'pending'
                            ? 'pending'
                            : response.data.status === 'claim'
                                ? 'claim'
                                : 'active';

                    setTaskStatuses((prevStatuses) => ({
                        ...prevStatuses,
                        [taskId]: newStatus
                    }));

                    toast.success("Task completed successfully!");
                    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
                    setCompletedTasks([]); // Optionally refresh completed tasks
                    setPage(1);
                    loadTasks(true);
                } else if (response.status === 201) {
                    toast.success("Task is already scheduled for completion.");
                } else {
                    toast.error("Failed to complete task. Please try again later.");
                }
            })
            .catch((error) => {
                setTaskStatuses((prevStatuses) => ({
                    ...prevStatuses,
                    [taskId]: 'active'
                }));
                console.error("Error completing task:", error);
                toast.error("Failed to complete task. Please try again later.");
            });
    };

    return (
        <div id="tasks">
            {/* Tabs */}
            <div id="tabs" className="flex justify-center text-center mb-4">
                <button
                    onClick={() => handleTabClick("active")}
                    className={`tab-toggler flex-grow w-full rounded-s-full px-4 py-2 text-sm ${
                        activeTab === "active"
                            ? "bg-gradient-to-r from-blue-500 to-cyan-500 text-white"
                            : "bg-gray-700 text-white"
                    } rounded-l-lg focus:outline-none`}
                >
                    Active
                </button>
                <button
                    onClick={() => handleTabClick("completed")}
                    className={`tab-toggler flex-grow w-full rounded-e-full px-4 py-2 text-sm ${
                        activeTab === "completed"
                            ? "bg-gradient-to-r from-blue-500 to-cyan-500 text-white"
                            : "bg-gray-700 text-white"
                    } rounded-l-lg focus:outline-none`}
                >
                    Completed
                </button>
            </div>
            <div
                id="tab-contents"
                className="overflow-y-scroll hide-scrollbar"
                style={{maxHeight: "calc(100vh - 150px)"}}
                onScroll={handleScroll}
                ref={containerRef}
            >
                {/* Task List */}
                {activeTab === "active" && (
                    <div id="active-tab">
                        <div className="max-w-md w-full mx-auto">
                            {tasks.length === 0 && !loading && (
                                <p className="text-center text-gray-500">No active tasks available.</p>
                            )}
                            {tasks.map((task, index) => (
                                <motion.div
                                    key={task.id || index}
                                    initial={{opacity: 0, y: 10}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{duration: 0.2, delay: index * 0.1}}
                                    className="flex items-center p-4 bg-gray-700 rounded-lg mb-2"
                                >
                                    <img
                                        src={getImageForPlatform(task.platform)}
                                        alt={task.platform}
                                        className="w-12 h-12 rounded-full"
                                    />
                                    <div className="flex-1 ml-4">
                                        <p className="text-xs text-gray-300">{task.title}</p>
                                        <p className="bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
                                            +<span className="font-bold">$</span> {task.reward}
                                        </p>
                                    </div>
                                    <button
                                        className={`inline-block px-4 py-2 text-xs text-white rounded-full focus:outline-none ${
                                            taskStatuses[task.id] === 'pending'
                                                ? 'bg-gradient-to-r from-orange-500 to-green-500 hover:bg-gradient-to-l'
                                                : 'bg-gradient-to-r from-purple-500 to-indigo-500 hover:bg-gradient-to-l'
                                        }`}
                                        onClick={() => handleStartTask(task)}
                                    >
                                        {taskStatuses[task.id] === 'pending' ? 'Pending' : task.status === 'claim'
                                            ? 'Claim' :  'Start'}
                                    </button>
                                </motion.div>
                            ))}
                            {loading && <Loading/>}
                        </div>
                    </div>
                )}
                {activeTab === "completed" && (
                    <div id="completed-tab">
                        <div className="max-w-md w-full mx-auto">
                            {completedTasks.length === 0 && !loading && (
                                <p className="text-center text-gray-500">You haven't completed any tasks yet.</p>
                            )}
                            {completedTasks.map((task, index) => (
                                <motion.div
                                    key={task.id || index}
                                    initial={{opacity: 0, y: 10}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{duration: 0.2, delay: index * 0.1}}
                                    className="flex items-center p-4 bg-gray-700 rounded-lg mb-2 opacity-85"
                                >
                                    <img
                                        src={getImageForPlatform(task.platform)}
                                        alt={task.platform}
                                        className="w-12 h-12 rounded-full"
                                    />
                                    <div className="flex-1 ml-4">
                                        <p className="text-xs text-gray-300">{task.title}</p>
                                        <p className="bg-gradient-to-r from-orange-400 to-yellow-600 inline-block text-transparent bg-clip-text">
                                            +<span className="font-bold">$</span> {task.reward}
                                        </p>
                                    </div>
                                    <div className="flex items-center ml-4 text-green-500">
                                        <CheckCircle size={14} className="mr-1"/>
                                        <span className="text-xs">Claimed!</span>
                                    </div>
                                </motion.div>
                            ))}
                            {loading && <Loading/>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tabs;
